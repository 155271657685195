<script setup lang="ts">
import { CommonRadioButtonList } from '#components'
import { PropType } from 'vue'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IdModal } from '~~/src/constants/IdModal'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { useModalStore } from '~~/src/store/modals'
import { ISelectable } from '~~/src/types/Selectable'


const props = defineProps({
    entries: { type: Object as PropType<String[]>, required: true },
    selectedEntry: { type: Number, default: -1 },
    forcedText: String,
})
const { entries, selectedEntry, forcedText } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])
const modalStore = useModalStore()

const currentEntry = computed(() => selectedEntry.value >= 0 && selectedEntry.value < entries.value.length ?
    entries.value[selectedEntry.value]
    : useLocale().translate(TranslationKeys.CHOSE_AN_OPTION)
)

const target = ref(null)

const modalProps = computed(() => ({
    items: entries.value.map((el, index) => ({
        id: el + "_" + index,
        element: el as TranslationKey,
        selected: index == selectedEntry.value,
    })),
    onChange: onClickk
}))

const onClick = () => {
    modalStore.addMobileModal({ allowClose: true, component: CommonRadioButtonList, props: modalProps, title: { key: TranslationKeys.ROOM_TYPE } })
}

const onClickk = (val: ISelectable<TranslationKey, any>[]) => {
    emit(EmitsEnum.Click, val.findIndex(el => el.selected))
    modalStore.closeModal(IdModal.Mobile)
}

</script>

<template>
    <div ref="target" class="relative w-full">
        <div class="flex gap-2 items-center" @click="onClick">
            <CommonText :text="((forcedText || currentEntry) as TranslationKey)" :text-size="TextSizes.HEADING_MD"
                class="truncate" />
            <CommonIcon class="shrink-0" :icon-name="SpSvg.BasicCarretDown" :fill="'fill-black'"
                :icon-size="IconSizes.XS" />
        </div>
    </div>
</template>