<script lang="ts" setup>
import { CommonDropdown, CommonDropdownCompact } from '#components'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { TestIds } from '../../constants/TestIds'

const props = defineProps({
	selectedId: String,
	disabled: Boolean,
	compact: Boolean,
	light: Boolean,
	testIds: {
		type: { container: String, dropdown: String },
		required: false,
	},
})
const { selectedId, disabled, compact, light } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])
const accommodationStore = useAccommodationsStore()

const display = computed(() => accommodationStore.accommodations.length > 1)
const getDropdownEntries = computed(() => accommodationStore.accommodations.map((entry) => entry.name))
const getCurrentDropdownIndex = computed(() => {
	if (selectedId?.value == undefined) {
		return -1
	}
	return accommodationStore.accommodations.findIndex((entry) => entry.id === selectedId!.value!)
})

const onDropdownChange = (index: number) => {
	const id = accommodationStore.accommodations[index]?.id
	emit(EmitsEnum.Change, id)
}

const componentToUse = computed(() => (compact.value ? CommonDropdownCompact : CommonDropdown))
</script>

<template>
	<div
		:data-id="UserflowId.AccommodationDropdown"
		:class="!display ? 'truncate' : ''"
		:data-testid="testIds?.container"
	>
		<component
			v-if="display"
			:is="componentToUse"
			:entries="getDropdownEntries"
			:selected-entry="getCurrentDropdownIndex"
			@click="onDropdownChange"
			:disabled="disabled"
			:light="light"
			:test-id="testIds?.dropdown"
		/>
		<CommonText
			v-else-if="accommodationStore.accommodations[0] != undefined"
			:text="accommodationStore.accommodations[0].name as TranslationKey"
			class="font-semibold"
		/>
	</div>
</template>
